import React, { useEffect, useMemo, useState } from 'react'
import { Session02Wrapper } from '../../MyAdessoJourney/session/session-02/Wrapper'
import { Link } from 'react-router-dom'
import { ErrorMessage, FormField, Logo } from '../../Common'
import InputField from '../Form/InputField'
import { sesstion02Questions } from '../../../lib/data/session-02'
import { useFormik } from 'formik'
import { useMAJPage } from '../../../hooks/pages/maj'
import { ButtonMyHealth } from '../Button'
import YourTargetHeartRate from './YourTargetHeartRate'
import * as Yup from 'yup'
import { useAlert } from '../../../context/AlertContext'
import { useBoolean } from '../../../hooks/use-boolean'

type TCalculateProps = {
  prevScreen?: () => void
  nextScreen?: () => void
}

const ssHAQuestions = [sesstion02Questions[29], sesstion02Questions[30]]

const data_ss02__028 = [
  { label: 'I don’t exercise regularly', value: 0 },
  { label: 'I have an active lifestyle with sporadic exercise', value: 1 },
  { label: 'I have a regular workout routine', value: 2 },
]

const CalculateYourTargetHeartRate = ({ prevScreen, nextScreen }: TCalculateProps) => {
  const { postDataSessions } = useMAJPage()
  const [screen, setScreen] = useState(-1)
  const [isCheckError, setIsCheckError] = useState<boolean>(false)
  const [dataQuestion, setDataQuestion] = useState({ ss02__027: 0, ss02__028: '' })
  const { setAlertError } = useAlert()
  const isLoading = useBoolean()

  const handlePrevScreen = () => {
    setScreen((prev) => prev - 1)
  }

  const handleNextScreen = () => {
    setScreen((prev) => prev + 1)
  }

  const formik = useFormik({
    initialValues: { ss02__027: '', ss02__028: '' },
    validationSchema: Yup.object({
      ss02__027: Yup.string().required('Field is required'),
      ss02__028: Yup.string().required('Field is required'),
    }),
    onSubmit: async (values) => {
      try {
        isLoading.onTrue()
        setDataQuestion({ ss02__027: Number(values?.ss02__027), ss02__028: values?.ss02__028 })
        await postDataSessions({ ss02__027: String(values?.ss02__027), ss02__028: values?.ss02__028 }, 2)
      } catch (error) {
        isLoading.onFalse()
        setAlertError(`${error}`)
      } finally {
        isLoading.onFalse()
        handleNextScreen()
      }
    },
  })

  const handleSubmitForm = () => {
    setIsCheckError(true)
    formik.handleSubmit
  }

  const screenHeartAssessment02 = useMemo(() => {
    const allValuesEmpty = Object.values(formik.values).some((value) => value === '')

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className="space-y-5">
          <div
            className="absolute left-0 right-0 top-0 z-10 h-[250px] bg-slate-300 bg-cover bg-[center_top] bg-no-repeat"
            style={{ backgroundImage: 'url(/images/my-adresso-journey/session/ss2_pc.png)' }}
          />
          <header className="relative z-20 w-full p-4">
            <div className="w-full">
              <Link to="/">
                <Logo className="block h-[34px] w-[145px] hover:opacity-80 md:mx-auto" isLogoWhite={true} />
              </Link>
            </div>
            <h1
              className={`flex h-[101px] w-full items-center font-butler text-[33px] leading-[35px] text-white md:justify-center`}
              dangerouslySetInnerHTML={{ __html: 'Get Your Move On: Exercise' }}
            />
          </header>

          <div className="flex h-full w-full flex-1 flex-col">
            <div className="flex-1 space-y-6 text-[18px] leading-[22px]">
              <h4 className="text-[24px] font-bold text-color-bg-primary">Calculate your Target Heart Rate.</h4>
              <img className="block h-auto w-full" src="/images/my-adresso-journey/session/02/img_007.jpg" alt="" />
              <div className="space-y-6">
                <p>Wondering how you calculate your Target Heart Rate? Here’s a formula you can use on your own.</p>
                <p>If you have a CPET test conducted, it’s easier to tailor an exercise plan that fits with you.</p>
                <p>In general, this is what the AHA recommends. You can figure out what your target heart rate goal is for exercised based on a simple calculation:</p>
              </div>
            </div>
          </div>

          <div className="space-y-5">
            <InputField
              idInput="ss02__027"
              nameInput="ss02__027"
              type="number"
              max={90}
              min={1}
              placeholder="[enter your age]"
              classNameInput="!bg-ma-pink-100 dark:!bg-ma-pink-100 px-3 shadow-none border-none focus:outline-0"
              value={formik?.values?.ss02__027}
              onChange={formik.handleChange}
            />
            {isCheckError && formik.errors.ss02__027 && <ErrorMessage text={formik.errors.ss02__027} />}
            <div>
              {data_ss02__028.map((item, index) => {
                return (
                  <div className="mb-4 flex items-center">
                    <input
                      id={`default-radio-${index}`}
                      type="radio"
                      value={item.label}
                      name="ss02__028"
                      className="peer hidden"
                      checked={formik.values.ss02__028 === item?.label}
                      onChange={formik.handleChange}
                    />
                    <label
                      htmlFor={`default-radio-${index}`}
                      className={`flex cursor-pointer bg-[length:21px_auto] bg-[top_1px_left] bg-no-repeat pl-7 pt-[1px] text-ma-md peer-checked:bg-[url('assets/images/icon/i-radio__checked.svg')] ${`bg-[url('assets/images/icon/i-radio__uncheck.svg')]`}`}
                    >
                      {item?.label}
                    </label>
                  </div>
                )
              })}
            </div>
            {isCheckError && formik.errors.ss02__028 && <ErrorMessage text={formik.errors.ss02__028} />}
          </div>
          <div className="flex flex-row justify-center gap-4">
            <ButtonMyHealth nameButton="Back" prevScreen={prevScreen} bgButton="bg-color-primary" />
            <ButtonMyHealth
              className={`${isLoading.value ? 'cursor-wait px-[50px] opacity-25' : ''}`}
              isLoading={isLoading.value}
              disabled={isLoading.value}
              nameButton="Next"
              submit={handleSubmitForm}
              bgButton={`${allValuesEmpty ? 'bg-color-text-secondary' : 'bg-color-primary '}`}
            />
          </div>
        </div>
      </form>
    )
  }, [formik, isCheckError])

  const calculate = useMemo(() => {
    switch (screen) {
      case 0:
        return <YourTargetHeartRate prevScreen={handlePrevScreen} prevHome={prevScreen} dataQuestion={dataQuestion} />
      default:
        return <>{screenHeartAssessment02}</>
    }
  }, [screen, formik, dataQuestion])

  return <>{calculate}</>
}

export default CalculateYourTargetHeartRate
