import { useCallback, useState } from 'react'
import { useAuth } from '../use-auth'
import { useLocalStorage } from '../use-local-storage'

import { AdessoResult, HeartScoreResult } from '../../models'
import { getHeartScoreResult } from '../../lib/request/get-heartscore-result'
import { mapDataHeartScoreResult } from '../../lib/maps/heart-score-result'

type Response = {
  results?: HeartScoreResult
}

export function useHeartScoreResultPage() {
  const [resultHeartScore, setResultHeartScore] = useState<AdessoResult<Response>>()

  const auth = useAuth()
  const { setItemLS, getItemLS } = useLocalStorage()

  const getResultHeartScore = useCallback(async () => {
    setResultHeartScore({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      if (await auth.checkAuth()) {
        auth.setTokenApi()
        const res = await getHeartScoreResult()

        if (res.data) {
          const results = mapDataHeartScoreResult(res.data)
          setItemLS('resultHeartScore', res.data)

          setResultHeartScore({
            isLoading: false,
            data: { results },
            error: undefined,
          })
        } else {
          setResultHeartScore({
            isLoading: false,
            data: undefined,
            error: auth.throwError(undefined, res.message),
          })
        }
      }
    } catch (error) {
      setResultHeartScore({
        isLoading: false,
        data: undefined,
        error: auth.throwError(error, 'Get HeartScore Result Error'),
      })
    }
  }, [])

  const checkResultHeartScore = useCallback(async () => {
    const dataResultHS = getItemLS('resultHeartScore')

    if (!dataResultHS) {
      getResultHeartScore()
    }
  }, [])

  return { resultHeartScore, getResultHeartScore, checkResultHeartScore }
}
