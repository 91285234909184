import axios from 'axios'

import { AdessoError } from '../../lib/error'
import { LoginOptions } from '../../models/login'

type LoginResponse = {
    token?: string
    user?: {
        id: number
        name?: string
        email: string
        phone_number: string
    }
    errorMessage?: string
    screen?: any
}

export async function postLogin ( params: LoginOptions ): Promise<LoginResponse> {
    const { data, status } = await axios.post( '/login', params )

    if ( status !== 200 ) {
        console.info( 'postLogin', data )
        throw new AdessoError( `Request login error - ${status} ${JSON.stringify( data )}` )
    }

    let dataReturn: LoginResponse
    if ( data.message === 'Success!' ) {
        dataReturn = {
            token: data.data.token,
            user: data.data.user,
            screen: data.data.screen
        }
    } else {
        dataReturn = {
            errorMessage: data.message,
        }
    }

    return dataReturn
}
