import { useCallback, useState } from 'react'
import { AxiosError } from 'axios'

import { useLocalStorage } from './use-local-storage'

import { configTokenAPI } from '../lib/open-api'
import { getCsrfCookie, postLogout, postLogin, postRegister } from '../lib/request'
import { AdessoError } from '../lib/error'

import { LoginOptions, RegisterOptions } from '../models/'
import { postUpdateIntroduction } from '../lib/request/post-update-introduction'
import { getMe } from '../lib/request/get-me'
import { getToken as getLoginToken } from '../lib/request/get-token'
import { IUser } from '../models/user'
import { checkHadFirstHeartScore } from '../lib/utils/helper'

export function useAuth() {
  const { setItemLS, getItemLS } = useLocalStorage()

  const [user, setUser] = useState<any>()
  const [loading, setLoading] = useState(false)

  const checkAuth = useCallback(async (): Promise<boolean> => {
    try {
      // const check = await getCsrfCookie()
      // return Boolean(check)

      return true
    } catch (error) {
      throw new AdessoError('Error checkAuth')
    }
  }, [])

  const login = useCallback(async (params: LoginOptions): Promise<any> => {
    try {
      const res = await postLogin(params)
      let isHadFirstHeartScore = false
      if (res.token) setItemLS('token', res.token)
      if (res.user) {
        setItemLS('user', res.user)
        isHadFirstHeartScore = checkHadFirstHeartScore(res.user)
      }

      // login userr
      setTokenApi()

      return { screen: isHadFirstHeartScore ? [] : res.screen }
    } catch (error) {
      throw throwError(error, 'Login Failed')
    }
  }, [])

  const getToken = useCallback(async (code: string): Promise<boolean> => {
    try {
      const res = await getLoginToken(code)
      if (res.token) setItemLS('token', res.token)
      if (res.user) setItemLS('user', res.user)

      // login userr
      setTokenApi()

      return Boolean(res)
    } catch (error) {
      return false
    }
  }, [])

  const register = useCallback(async (params: RegisterOptions): Promise<boolean> => {
    try {
      const res = await postRegister(params)

      if (res.token) setItemLS('token', res.token)
      if (res.user) setItemLS('user', res.user)
      // login userr
      setTokenApi()

      return Boolean(res)
    } catch (error: any) {
      throw throwError(undefined, error.response.data.data.email[0] || 'Register Failed')
    }
  }, [])

  const logout = useCallback(async (screen = ''): Promise<void> => {
    try {
      await postLogout(screen)
    } catch (error) {
      throw new AdessoError('Error Logout')
    }
  }, [])

  // Trigger to update user local data
  const getMeResult = useCallback(async () => {
    try {
      setLoading(true)
      const res = await getMe()
      if (res.data) {
        setItemLS('user', res.data)
        setUser(res.data)
      }
      return res
    } catch (error) {
      throw new AdessoError('Error getMeResult')
    } finally {
      setLoading(false)
    }
  }, [])

  const setIntroduced = useCallback(async () => {
    try {
      const res = await postUpdateIntroduction()
      if (res.data) setItemLS('user', res.data)
      return res
    } catch (error) {
      throw throwError(error, 'Update Introduction Failed')
    }
  }, [])

  const updateUserLocal = useCallback((data: Partial<IUser>) => {
    const u = getItemLS('user') as IUser
    if (u) setItemLS('user', { ...u, ...data })
  }, [])

  const throwError = useCallback((error: AdessoError | AxiosError | any, errorMessage: string = ''): AdessoError => {
    if (error instanceof AdessoError) return error

    if (!(error instanceof AxiosError)) {
      return new AdessoError(errorMessage)
    }

    const data = error?.response && error.response.data

    if (data.errors !== undefined) {
      let mes = ''
      for (const key in data.errors) {
        mes = data.errors[key][0]
      }
      return new AdessoError(mes)
    } else {
      return new AdessoError(data.message)
    }
  }, [])

  const setTokenApi = () => {
    const token = getItemLS('token')

    if (typeof token === 'string') {
      configTokenAPI(token)
    }
  }

  return { loading, user, updateUserLocal, getMeResult, login, getToken, register, logout, checkAuth, throwError, setTokenApi, setIntroduced }
}
