declare global {
  interface Window {
    _mtm: any[]
  }
}

import { Navigate, Outlet, useLocation } from 'react-router-dom'
import './App.css'
import { useLocalStorage } from './hooks/use-local-storage'
import { configAxiosDefault, configTokenAPI } from './lib/open-api'
import { useAuth } from './hooks/use-auth'
import { useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'

function App() {
  const location = useLocation()

  const ALLOW_PATH_REFRESH_USER_DATA = ['/', '/dashboard', '/introduction', '/subscription', '/heart-score']
  const currentLocation = location.pathname
  const homePath = import.meta.env.VITE_HOME_PATH
  const loginPath = import.meta.env.VITE_LOGIN_PATH

  const refreshUserLocal = ALLOW_PATH_REFRESH_USER_DATA.includes(location.pathname)

  const { getItemLS } = useLocalStorage()
  const token = getItemLS('token') as string
  const { user, getMeResult } = useAuth()
  configAxiosDefault()
  configTokenAPI(token)
  // import amplitude api key value from env file
  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, { defaultTracking: true })

  useEffect(() => {
    if (token) getMeResult().catch(() => {})
  }, [refreshUserLocal])

  if (user) {
    if (user?.hash_id) {
      amplitude.setUserId(user?.hash_id)
    }
    if (user?.program_id) {
      // set custom property in amplitude user look up
      let identifyvalue = new amplitude.Identify().set('Program ID', user?.program_id)
      amplitude.identify(identifyvalue)
    }
  }

  if (currentLocation === '/' && token !== null && homePath !== '/') {
    // return <Navigate to={homePath} replace={true}></Navigate>
    return <Navigate to={'/dashboard'} replace />
  }

  return (
    <div className="flex h-full min-h-screen flex-col items-center font-sans">
      <Outlet />
    </div>
  )
}

export default App
