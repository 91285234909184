import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHeartJournalPage } from '../../hooks/pages/heart-journal'
import { Goal } from '../../models/goal'
import { HeartJournalWrapper } from './Wrapper'
import { CalanderScreen } from '../../constants'
import { useHeartJournalActionPage } from '../../hooks/pages/heart-journal-action'
import { FormQuestionsContent } from './Form/FormQuestionsContent'
import { Loading } from '../Common'
import { LoadingSize } from '../../models/loading'
import { useLocation } from 'react-router-dom'
import { useHeartJournal } from '../../hooks/use-heart-journal'
import { CustomCalendar } from '../Calendar'
import { format, isAfter, isSameDay, isSameMonth, startOfMonth, subMonths } from 'date-fns'

type Props = {
  isIntroduction?: boolean
}
interface IHCalendarJHistory {
  date: string
  goal_id: number[]
}

const DATE_FORMAT = 'yyyy-MM-dd'

export const HeartJournalPageContent = ({ isIntroduction }: Props) => {
  const search = useLocation().search
  const slugParam = new URLSearchParams(search).get('slug') as string
  const { myGoals, allGoals } = useHeartJournal()
  const { getCalendarHistories, getHJHistory } = useHeartJournalPage()
  const { dataResult, getGoalData } = useHeartJournalActionPage()
  const [slug, setSlug] = useState('')
  const [goals, setGoals] = useState<Goal[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [date, setDate] = useState(new Date())
  const [month, setMonth] = useState(new Date())
  const [trackedDate, setTrackedDate] = useState<String[]>([])
  const [myGoalIds, setGoalId] = useState<number[]>([])
  const [allGoal, setAllGoals] = useState<Goal[]>([])
  const [dataHistoryCalendar, setDataHistoryCalendar] = useState<IHCalendarJHistory[]>([])
  const [calendarDate, setCalendarDate] = useState(new Date())
  const [startCalendarDate, setStartCalendarDate] = useState(new Date())
  const [answserHistory, setAnswserHistory] = useState<any[]>([])

  const handleHeartJournal = useCallback(async (newSlug: string) => {
    if (slug === newSlug) {
      return
    }
    setSlug(newSlug)
  }, [])

  const getCurrentGoalId = () => {
    const currentGoal = allGoal?.find((f) => f.slug === slug)
    return currentGoal?.id || (null as any)
  }

  const getCurrentGoalTrackingId = () => {
    const currentGoal = allGoal?.find((f) => f.slug === slug)
    return currentGoal?.goalTrackingId || null
  }

  useEffect(() => {
    const fetchAllGoals = async () => {
      const goals = await allGoals()
      setAllGoals(goals?.data)
    }

    fetchAllGoals()
  }, [])

  useEffect(() => {
    const fetchGoals = async () => {
      const goals = await myGoals()
      const initSlug = slugParam || goals?.data[0]?.slug
      setSlug(initSlug)
      setGoalId(goals?.data.map((goal: Goal) => goal.id))
      setGoals(goals?.data)
    }
    fetchGoals()
  }, [])

  useEffect(() => {
    if (!slug || !allGoal.length) {
      setLoading(false)
      return
    }

    setLoading(true)
    const fetchDetail = async () => {
      try {
        const d = format(date, DATE_FORMAT)
        const goalId = getCurrentGoalId()
        const [, data] = await Promise.all([getGoalData(slug), getHJHistory(d, goalId)])
        setAnswserHistory(data?.data as any)
      } catch (error) {}

      setLoading(false)
    }
    fetchDetail()
  }, [slug, date, allGoal])

  useEffect(() => {
    if (isAfter(month, startCalendarDate) && dataHistoryCalendar.length) {
      return
    }

    const fetchCalendar = async () => {
      const newStartDate = subMonths(startOfMonth(startCalendarDate), 3)
      const data = await getCalendarHistories(format(newStartDate, DATE_FORMAT), format(new Date(), DATE_FORMAT)).catch()
      setStartCalendarDate(newStartDate)
      setDataHistoryCalendar(dataHistoryCalendar.concat(data?.data as any[]))
    }
    fetchCalendar()
  }, [month])

  useEffect(() => {
    if (!slug || !dataHistoryCalendar.length || !allGoal.length) {
      return
    }
    const goalId = getCurrentGoalId()
    const archived = dataHistoryCalendar
      .filter((m: any) => {
        return isSameMonth(month, new Date(m.date)) && m.goal_id?.includes(goalId)
      })
      .map((d: any) => d.date)

    setTrackedDate([...archived])
  }, [slug, dataHistoryCalendar, month, calendarDate, allGoal])

  const onCloseCalendar = async () => {
    setMonth(new Date(date))
    setCalendarDate(new Date(date))
  }
  const onChangeMonth = async (start: string, end: string) => {
    setMonth(new Date(start))
    setCalendarDate(new Date(start))
  }
  const onSelectedDate = (date: string) => {
    if (isSameDay(date, new Date())) {
      setGoals(allGoal.filter((g: any) => myGoalIds.includes(g.id)))
    } else {
      const existGoal = dataHistoryCalendar.find((f: IHCalendarJHistory) => f.date === date)
      const newGoals = allGoal.filter((g: any) => existGoal?.goal_id?.includes(g.id))
      setGoals(newGoals)
    }

    setDate(new Date(date))
    setCalendarDate(new Date(date))
  }

  const questionsPage = useMemo(() => {
    return dataResult?.data?.goal && <FormQuestionsContent anwsers={answserHistory} goal={dataResult?.data?.goal} trackingId={getCurrentGoalTrackingId()} />
  }, [slug, dataResult?.data, answserHistory])

  const myHeartJournalsList = useMemo(() => {
    return (
      <div className="no-scrollbar flex space-x-3 overflow-auto whitespace-nowrap">
        {goals?.map((item: Goal, index) => {
          return (
            <button
              key={index}
              onClick={() => handleHeartJournal(item.slug)}
              className="rounded-full p-[5px] px-[10px] uppercase hover:cursor-pointer"
              style={
                slug === item.slug
                  ? {
                      backgroundColor: item?.backgroundColor,
                      color: '#FFF',
                    }
                  : {
                      backgroundColor: '#FFF',
                    }
              }
            >
              {item?.title}
            </button>
          )
        })}
      </div>
    )
  }, [slug, goals])
  const calendar = useMemo(() => {
    return (
      <>
        <CustomCalendar
          togglePopover={true}
          date={calendarDate}
          month={month}
          trackedDate={trackedDate}
          screen={CalanderScreen.HJ}
          onSelectedDate={onSelectedDate}
          onChangeMonth={onChangeMonth}
          onClosed={onCloseCalendar}
        />
      </>
    )
  }, [calendarDate, trackedDate])

  return (
    <HeartJournalWrapper className="mx-auto max-w-[684px] space-y-4 pb-12">
      {loading && (
        <div className="flex justify-center">
          <Loading size={LoadingSize.Small} isFull={true} />
        </div>
      )}
      {calendar}
      {myHeartJournalsList}

      {questionsPage}
    </HeartJournalWrapper>
  )
}
