import axios from 'axios'

import { AdessoError } from '../error'

type Response = {
  data?: {
    status: string
    level_up: any
    beats: any
    score?: number
    message?: any
  }
  message?: string
}

export async function postFinishSessions(params: object): Promise<Response> {
  const { data, status } = await axios.post('/journeys/finish', params)

  if (status !== 200) {
    throw new AdessoError(`Request postFinishSessions error - ${status} ${JSON.stringify(data)}`)
  }

  const dataReturn: Response = data.message === 'Success!' ? { data: data.data } : { message: data.message }

  return dataReturn
}
