import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAuth } from '../use-auth'
import { useLocalStorage } from '../use-local-storage'
import { AdessoError } from '../../lib/error'
import { AdessoResult } from '../../models/adesso'

type Result = {
  isAuth: boolean
}

export function useLogoutPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [pageDataResult, setPageDataResult] = useState<AdessoResult<Result>>()
  const auth = useAuth()
  const { deleteItemLS } = useLocalStorage()

  const screen = searchParams.get('scr')

  const resetSiteData = useCallback(() => {
    deleteItemLS('token')
    deleteItemLS('user')
    deleteItemLS('myNow')
    deleteItemLS('onboarded')
    deleteItemLS('resultHeartScore')
    deleteItemLS('doneIntroduction')
  }, [])

  const getPageData = useCallback(async () => {
    setPageDataResult({
      isLoading: true,
      data: undefined,
      error: undefined,
    })

    try {
      const isAuth = await auth.checkAuth()
      setPageDataResult({
        isLoading: false,
        data: { isAuth },
        error: undefined,
      })
    } catch (error) {
      setPageDataResult({
        isLoading: false,
        data: undefined,
        error: new AdessoError('Get page data: Failed!'),
      })
    }
  }, [])

  const logout = useCallback(async () => {
    resetSiteData()
    await auth.logout(screen || '')
    setTimeout(() => {
      window.location.href = '/login'
    }, 2000)
  }, [])

  return { pageDataResult, getPageData, logout }
}
