import axios from 'axios'

import { AdessoError } from '../../lib/error'
import { RegisterOptions } from '../../models/register'

type RegisterResponse = {
  token?: string
  user?: {
    id: number
    name?: string
    email: string
    phone_number: string
  }
  errorMessage?: string
}

export async function postRegister(params: RegisterOptions): Promise<RegisterResponse> {
  const { data, status } = await axios.post('/register', params)

  if (status !== 200) {
    throw new AdessoError(`Request login error - ${status} ${JSON.stringify(data)}`)
  }

  let dataReturn: RegisterResponse
  if (data.message === 'Success!') {
    dataReturn = {
      token: data.data.token,
      user: data.data.user,
    }
  } else {
    dataReturn = {
      errorMessage: data.message,
    }
  }

  return dataReturn
}
