import axios from 'axios'

import { AdessoError } from '../error'
import { LoginOptions } from '../../models/login'
import { AdessoResult } from "../../models";

type GetMeResult = {
    success: boolean,
    data?: any,
    message?: string
}

export async function getMyBeat (): Promise<GetMeResult> {
    const { data, status } = await axios.get( '/my-beats' )

    if ( status !== 200 ) {
        console.info( 'get me', data )
        throw new AdessoError( `Get my beat error - ${status} ${JSON.stringify( data )}` )
    }

    return data
}
