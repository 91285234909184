import axios from 'axios'
import { GoalDetail } from "../../models/goal";
import { AdessoError } from "../error";


type GetGoalResponse = {
    data?: GoalDetail
    message?: string
}

export async function getGoal ( slug: string ): Promise<GetGoalResponse> {
    const { data, status } = await axios.get( `/goals/detail/${slug}` )
    if ( data.success && data.data ) {
        data.data.backgroundColor = data.data.background_color
        data.data.textColor = data.data.text_color
    }
    if ( status !== 200 ) {
        console.info( 'getGoal', data )
        throw new AdessoError( `Request getGoal error - ${status} ${JSON.stringify( data )}` )
    }
    const dataReturn: GetGoalResponse = data.success ? {
        data: data.data,
        message: data.message
    } : {
        message: data.message,
    }

    return dataReturn
}
