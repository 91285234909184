export function toDate(date: string): Date {
  // Validate the date string
  const regex = /^(?:\d{4})-(?:0?[1-9]|1[0-2])-(?:0?[1-9]|[12][0-9]|3[01])$/
  if (!regex.test(date)) {
    throw new Error("Invalid date format. Use 'yyyy-MM-dd' or 'yyyy-M-d'.")
  }
  return new Date(date + 'T00:00:00')
}

export const checkHadFirstHeartScore = (user: any) => {
  return user.had_first_heart_score
}
